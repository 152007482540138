<template>
    <div class="fale-conosco">
        <div class="container">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Contato</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 offset-xl-3 mt-4">
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="contato_nome">Nome Completo*</label>
                        <input
                            type="text"
                            name="nome"
                            v-model="contato.nome"
                            class="form-control"
                            id="contato_nome"
                        />
                        <div class="invalid-feedback"></div>
                    </div>
                    <div class="col-12 form-group">
                        <label for="contato_email">E-mail*</label>
                        <input
                            type="email"
                            name="email"
                            v-model="contato.email"
                            class="form-control"
                            id="contato_email"
                        />
                        <div class="invalid-feedback"></div>
                    </div>
                    <div class="col-12 form-group">
                        <label for="contato_assunto">Assunto*</label>

                        <select
                            id="contato_assunto"
                            name="assunto"
                            v-model="contato.assunto"
                            class="form-control form-select"
                        >
                            <option value=""></option>
                            <option value="Dúvidas">Dúvidas</option>
                            <option value="Elogios">Elogios</option>
                            <option value="Reclamações">Reclamações</option>
                            <option value="Sugestões">Sugestões</option>
                            <option value="Orçamento Encomenda">Orçamento Encomenda</option>
                            <option value="Outros">Outros</option>
                        </select>

                        <div class="invalid-feedback"></div>
                    </div>
                    <div class="col-12 form-group">
                        <label for="contato_mensagem">Mensagem</label>
                        <textarea
                            name="mensagem"
                            class="form-control"
                            id="contato_mensagem"
                            v-model="contato.mensagem"
                            style="min-height: 150px"
                        ></textarea>
                        <div class="invalid-feedback"></div>
                    </div>

                    <div class="col-12 mt-1 form-group">
                        <button :disabled="loading"
                            type="submit"
                            @click.prevent="enviarEmail()"
                            class="btn btn-site mx-auto d-block"
                            id="contato_submit"
                        >
                            Enviar
                        </button>
                    </div>

                    <div v-if="loading" class="text-center mt-3">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

export default {
    name: "Contato",
    data() {
        return {
            contato: {
                nome: "",
                email: "",
                assunto: "",
                mensagem: "",
            },
            loading: false,
        };
    },

    methods: {
        validarEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },

        validarCampos() {
            if (this.contato.nome == "") {
                Vue.swal.fire("Nome", "Preencha o campo nome", "info");
                return false;
            }

            if (this.contato.email == "") {
                Vue.swal.fire("Email", "Preencha o campo e-mail", "info");
                return false;
            }

            if (!this.validarEmail(this.contato.email)) {
                Vue.swal.fire("Email", "Insira um e-mail válido", "info");
                return false;
            }

            if (this.contato.assunto == "") {
                Vue.swal.fire("Nome", "Defina um assunto", "info");
                return false;
            }

            if (this.contato.mensagem == "") {
                Vue.swal.fire("Mensagem", "Digite sua mensagem", "info");
                return false;
            }

            if (this.contato.mensagem.length < 25) {
                Vue.swal.fire(
                    "Mensagem",
                    "Sua mensagem deve ter no mínimo 25 caracteres",
                    "info"
                );
                return false;
            }

            return true;
        },

        enviarEmail() {
            if (this.validarCampos()) {
                var template = `
                
                <h2>Dados Contato</h2>

                <b>Nome:</b> ${this.contato.nome}<br>
                <b>E-mail:</b> ${this.contato.email}<br>

                <hr>

                <b>Mensagem:</b> <br>
                ${this.contato.mensagem}
                
                `;
                this.loading = true;
                this.axios
                    .post(`${process.env.VUE_APP_URL_TOOLS}/smtp-custom`, {
                        email: 'noreply@biatruffasedoces.com.br',
                        nome: this.contato.nome,
                        assunto: this.contato.assunto,
                        texto_html: template,
                        enviar: 'contato@biatruffasedoces.com.br'
                    })
                    .then((response) => {
                        if (response.data.success) {
                            Vue.swal
                                .fire(
                                    "Enviado",
                                    "Seu e-mail foi enviado com sucesso. Em breve entraremos em contato",
                                    "success"
                                )
                                .then(() => {
                                    this.contato = {
                                        nome: "",
                                        email: "",
                                        assunto: "",
                                        mensagem: "",
                                    };
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        Vue.swal.fire(
                            "Erro",
                            "Ocorreu um erro no envio. Tente novamente",
                            "error"
                        );
                    })
                    .then(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.fale-conosco {
    @include background;
    @include form;

    .titulo {
        @include titulo;
    }
    .btn-site {
        @include btn-site;
    }
}
@media (max-width: 767.98px) {
    .fale-conosco {
        padding: 50px 0 80px;
    }
}
</style>